<template>
	<v-container
		fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							item-key="id"
							:footer-props="{'items-per-page-options':[50, 100, 500]}"
						>
							<template v-slot:[`item.ts`]="{ item }">
								<span class="text-no-wrap">{{ item.ts }}</span>
							</template>
							<template v-slot:[`item.value`]="{ item }">
								<span class="text-no-wrap">{{ item.value }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { globalMixIn } from '@/App.vue'

export default {
	data: () => ({
		loading: false,
		headers: [
			{ text: 'Timestamp', value: 'ts' },
			{ text: 'ID', value: 'id' },
			{ text: 'IP', value: 'ip' },
			{ text: 'Method', value: 'key' },
			{ text: 'Ref', value: 'reference' },
			{ text: 'Ref Id', value: 'reference_id' },
			{ text: 'Value', value: 'value' },
		],
		items: [],
	}),
	methods: {
		refreshContent () {
			this.loading = true
			this.getApi(process.env.VUE_APP_API_URL + `/api/system/request-logs`)
				.then(res => {
					this.items = res
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
	mixins: [globalMixIn],
	mounted () {
		this.refreshContent()
	},
	name: `SystemRequestLogs`,
}
</script>
